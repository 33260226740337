import React, {Suspense} from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./assets/styles/index.scss";
import {BrowserRouter as Routes} from "react-router-dom";
import {ConfigProvider} from 'antd';
import { StyleProvider } from '@ant-design/cssinjs';
import {Provider} from "react-redux";
import store from "./store";
import zhCN from 'antd/locale/zh_CN';
import 'dayjs/locale/zh-cn';

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

root.render(
    <Provider store={store}>
        <Routes>
            <Suspense fallback={"loading..."}>
                <ConfigProvider  locale={zhCN}  theme={{token: {colorPrimary: '#eccc59'}}}>
                    <StyleProvider hashPriority="high">
                        <App/>
                    </StyleProvider>
                </ConfigProvider>
            </Suspense>
        </Routes>
    </Provider>
);


