import React, { lazy } from "react";

import { Navigate } from "react-router-dom";

const Layout = lazy(() => import("@/pages/Layout"));
const About = lazy(() => import("@/pages/About"));
const Home = lazy(() => import("@/pages/Home"));
const News = lazy(() => import("@/pages/News"));
const Service = lazy(() => import("@/pages/Service"));
const Market = lazy(() => import("@/pages/Market"));

export interface RouteConfig {
  path: string;
  element: React.ReactNode;
  auth?: boolean;
  children?: RouteConfig[];
  redirect?:string
}


const route:RouteConfig[] = [
  {
    path: "/",
    element: <Navigate to="/home"></Navigate>,
  },
  {
    path: "/home",
    element: <Layout></Layout>,
    children: [
      {
        path: "/home",
        element: <Navigate to="/home/index"></Navigate>,
      },
      {
        path: "/home/index",
        element: <Home></Home>,
      },
      {
        path: "/home/about",
        element: <About></About>,
      },
      {
        path: "/home/news",
        element: <News></News>,
      },
      {
        path: "/home/service",
        element: <Service></Service>,
      },
      {
        path: "/home/market",
        element: <Market></Market>,
      },
    ],
  },
  {
    path: "*",
    element: <Navigate to="/home"></Navigate>,
  },
];

export default route;
