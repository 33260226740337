import React, {createContext, useEffect, useState} from "react";
import pako from 'pako';

const StompJs = require('@/utils/stomp').Stomp;

export const GlobalContext = createContext('');

const Websocket:React.FC<{children:React.ReactNode}> = ({children}) => {

    const client = StompJs.over(new WebSocket('wss://api.beijingzxj.com/market/websocket'));

    const topic = '/price/all'

    const unzip = (b64Data:string) => {
        let strData = atob(b64Data);
        const charData = strData.split("").map(function(x) {
            return x.charCodeAt(0);
        });
        const binData = new Uint8Array(charData);
        const data = pako.inflate(binData);
        let newStrData = "";
        for (let i = 0; i < data.length; ++i) {
            newStrData += String.fromCharCode(data[i]);
        }
        return JSON.parse(decodeURIComponent(escape(newStrData)));
    }

    const [price, setPrice] = useState<string>('')

    const getBuyBackItemData = (oldV: any, newV: any) => {
        if (newV.huigou) {
            newV['color'] = !oldV ? 'red' :  newV.huigou > oldV.huigou ? 'red' : 'green'
            return{...oldV,...newV}
        }
        return oldV
    }

    const [priceList, setPriceList] = useState<any>({})

    const newListSort = (resBody: any) => {
        console.log('resBody',resBody)
        const shangJinSuo: any = priceList;
        for (const i in resBody) {
            if (i === 'huangjin') {
                for (const b in resBody['huangjin']['prices']) {
                    shangJinSuo[b] = shangJinSuo ? getBuyBackItemData(shangJinSuo[b], resBody[i].prices[b]) : resBody[i].prices[b]
                }
                setPriceList({...priceList,...shangJinSuo});
            }
            if (i === 'baiyin') {
                for (const b in resBody['baiyin']['prices']) {
                    shangJinSuo[b] = shangJinSuo ? getBuyBackItemData(shangJinSuo[b], resBody[i].prices[b]) : resBody[i].prices[b]
                }
                setPriceList({...priceList,...shangJinSuo});
            }
            if (i === 'bajin') {
                for (const b in resBody['bajin']['prices']) {
                    shangJinSuo[b] = shangJinSuo ? getBuyBackItemData(shangJinSuo[b], resBody[i].prices[b]) : resBody[i].prices[b]
                }
                setPriceList({...priceList,...shangJinSuo});
            }
            if (i === 'bojin') {
                for (const b in resBody['bojin']['prices']) {
                    shangJinSuo[b] = shangJinSuo ? getBuyBackItemData(shangJinSuo[b], resBody[i].prices[b]) : resBody[i].prices[b]
                }
                setPriceList({...priceList,...shangJinSuo});
            }
        }
    }

    useEffect(() => {
        setPrice(JSON.stringify(priceList))
    }, [priceList]);


    const responseCallback = (frame:any) => {
        const res_data = unzip(frame.body)
        newListSort(res_data)
    }

    const onConnected = () => {
        client.subscribe(topic, responseCallback, onFailed);
    }
    const onFailed = (frame:any) => {
        console.log("错误: " + frame);
        if (!client.connected) {
            setTimeout(() => {
                connect();
            }, 1000);
        }
    }
    const connect = () => {
        // ---初始化mqtt客户端，并连接mqtt服务
        const headers = {
            login: "username",
            passcode: "password"
        };
        client.connect(headers, onConnected, onFailed);
        client.debug = null;
    }

    useEffect(() => {
        connect()
    }, []);


    return <GlobalContext.Provider value={price}>
        {
            children
        }</GlobalContext.Provider>

}

export default  Websocket