import React from "react";
import {
    useRoutes,
    Route,
} from "react-router-dom";
import route, {RouteConfig} from "./route";
import AuthRoute from "./route/authRouter";
import Websocket from "@/components/Websocket";


function App() {
    // 处理我们的routers
    const RouteAuthFun = (
        (routeList: RouteConfig[]) => {
            return routeList.map(
                (item: {
                    path: string;
                    auth?: boolean;
                    element: React.ReactNode;
                    children?: any;
                }) => {
                    return (
                        <Route
                            path={item.path}
                            element={
                                <AuthRoute auth={item.auth} key={item.path}>
                                    {item.element}
                                </AuthRoute>
                            }
                            key={item.path}
                        >
                            {/* 递归调用，因为可能存在多级的路由 */}
                            {item?.children && RouteAuthFun(item.children)}
                        </Route>
                    );
                }
            );
        }
    )

    const outlet = useRoutes(route);
    return <div className="App">
        <Websocket>
            {outlet}
        </Websocket>
    </div>;
}

export default App;
